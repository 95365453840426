import Vue from "vue";

/*ユーザー系インスタンス用共通関数 */

Vue.prototype.$user_payment_loges = [
  { text: "支払い遅延 なし", value: 0 },
  { text: "支払い遅延中 1月以上", value: 1 },
  { text: "支払い遅延中 2月以上", value: 2 },
  { text: "支払い遅延中 3月以上", value: 3 }
];

Vue.prototype.$user_states = [
  { text: "契約中", value: 0 },
  { text: "アカウント停止中", value: 1 },
  { text: "契約停止中", value: 2 },
  { text: "契約解約", value: 3 }
];

Vue.prototype.$user_payment_log_name = val => {
  //    return user_payment_loges.find((item) => { item.value == val }).text;
  // メモ：なぜか上の書き方でエラーになるので代わりの処理。後で治す
  var ret;

  switch (val) {
    case 0:
      ret = "支払い遅延 なし";
      break;
    case 1:
      ret = "支払い遅延中 1月以上";
      break;
    case 2:
      ret = "支払い遅延中 2月以上";
      break;
    case 3:
      ret = "支払い遅延中 3月以上";
      break;
  }

  return ret;
};

Vue.prototype.$user_state_name = val => {
  //    return user_states.find((item) => { item.value == val }).text;
  // メモ：なぜか上の書き方でエラーになるので代わりの処理。後で治す
  var ret;

  switch (val) {
    case 0:
      ret = "契約中";
      break;
    case 1:
      ret = "アカウント停止中";
      break;
    case 2:
      ret = "契約停止中";
      break;
    case 3:
      ret = "契約解約";
      break;
  }

  return ret;
};

Vue.prototype.$form_car_user_infos_def = () => {
  return {
    user_id: "",
    first_name: "",
    name: "",
    work_postal: "",
    work_state: "",
    work_address: "",
    work_address_sub: "",
    work_phone: "",
    merchant: "",
    birthday: "",
    gender: "0",
    user_postal: "",
    user_state: "",
    user_address: "",
    user_address_sub: "",
    car_id: null,
    company_id: null,
    payment_log: 0,
    state: 0,
    detail: "",
    isDelete: false
  };
};
Vue.prototype.$form_car_user_err_infos_def = () => {
  return {
    common: "",
    user_id: "",
    first_name: "",
    name: "",
    work_postal: "",
    work_state: "",
    work_address: "",
    work_address_sub: "",
    work_phone: "",
    birthday: "",
    gender: "",
    user_postal: "",
    user_state: "",
    user_address: "",
    user_address_sub: "",
    car_id: "",
    company_id: "",
    payment_log: "",
    state: "",
    detail: ""
  };
};

Vue.prototype.$user_search_items = [
  { search_colum: "user_id", text: "顧客ID", value: "0" },
  { search_colum: "name", text: "名前", value: "1" },
  { search_colum: "company_name", text: "法人名", value: "2" },
  { search_colum: "merchant", text: "加盟店", value: "3" }
];

Vue.prototype.$car_add_user_search_items = [
  { search_colum: "user_id", text: "顧客ID", value: "0" },
  { search_colum: "name", text: "名前", value: "1" },
];
