import Vue from "vue";

/*GPS端末系インスタンス用共通関数 */

Vue.prototype.$form_company_infos_def = () => {
  return {
    company_id: "",
    company_name: "",
    name: "",
    address: "",
    phone: "",
    email: "",
  };
};
Vue.prototype.$form_company_err_infos_def = () => {
  return {
    company_id: "",
    company_name: "",
    name: "",
    address: "",
    phone: "",
    email: "",
  };
};

Vue.prototype.$company_search_items = [
  { search_colum: "company_id", text: "会社ID", value: "0" },
  { search_colum: "company_name", text: "会社名", value: "1" },
  { search_colum: "name", text: "担当者名", value: "2" },
  { search_colum: "address", text: "住所", value: "3" },
  { search_colum: "phone", text: "電話番号", value: "4" },
  { search_colum: "email", text: "メールアドレス", value: "5" }
];

