import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7e9d5975 = () => interopDefault(import('../pages/notify.vue' /* webpackChunkName: "pages/notify" */))
const _1d4c87d7 = () => interopDefault(import('../pages/password_reset.vue' /* webpackChunkName: "pages/password_reset" */))
const _ba3e4ac0 = () => interopDefault(import('../pages/warning.vue' /* webpackChunkName: "pages/warning" */))
const _21785731 = () => interopDefault(import('../pages/account/account_add.vue' /* webpackChunkName: "pages/account/account_add" */))
const _abdde284 = () => interopDefault(import('../pages/account/account_list.vue' /* webpackChunkName: "pages/account/account_list" */))
const _9d7e9a88 = () => interopDefault(import('../pages/account/password_reset/index.vue' /* webpackChunkName: "pages/account/password_reset/index" */))
const _39ed53ff = () => interopDefault(import('../pages/car/car_add.vue' /* webpackChunkName: "pages/car/car_add" */))
const _bf88a8a0 = () => interopDefault(import('../pages/car/car_list.vue' /* webpackChunkName: "pages/car/car_list" */))
const _7dfc04bf = () => interopDefault(import('../pages/car/car_user_add.vue' /* webpackChunkName: "pages/car/car_user_add" */))
const _69febd0a = () => interopDefault(import('../pages/car/car_user_device_add.vue' /* webpackChunkName: "pages/car/car_user_device_add" */))
const _43f9da20 = () => interopDefault(import('../pages/car/car_user_list.vue' /* webpackChunkName: "pages/car/car_user_list" */))
const _5bbc685e = () => interopDefault(import('../pages/company/company_add.vue' /* webpackChunkName: "pages/company/company_add" */))
const _0e962e1e = () => interopDefault(import('../pages/company/company_list.vue' /* webpackChunkName: "pages/company/company_list" */))
const _0bcc00a2 = () => interopDefault(import('../pages/car/device/add.vue' /* webpackChunkName: "pages/car/device/add" */))
const _34b71400 = () => interopDefault(import('../pages/car/device/list.vue' /* webpackChunkName: "pages/car/device/list" */))
const _48088e86 = () => interopDefault(import('../pages/car/device/detail/_car_device_id.vue' /* webpackChunkName: "pages/car/device/detail/_car_device_id" */))
const _2fd969ad = () => interopDefault(import('../pages/car/device/edit/_car_device_id.vue' /* webpackChunkName: "pages/car/device/edit/_car_device_id" */))
const _b3bf2524 = () => interopDefault(import('../pages/account/account_detail/_account_id.vue' /* webpackChunkName: "pages/account/account_detail/_account_id" */))
const _456a99b2 = () => interopDefault(import('../pages/account/account_edit/_account_id.vue' /* webpackChunkName: "pages/account/account_edit/_account_id" */))
const _3e3ce0dc = () => interopDefault(import('../pages/car/car_edit/_car_id.vue' /* webpackChunkName: "pages/car/car_edit/_car_id" */))
const _bc6f6c96 = () => interopDefault(import('../pages/car/car_loges/_car_data_id.vue' /* webpackChunkName: "pages/car/car_loges/_car_data_id" */))
const _7b6c7d6c = () => interopDefault(import('../pages/car/car_user_detail/_user_id.vue' /* webpackChunkName: "pages/car/car_user_detail/_user_id" */))
const _0bb2f45a = () => interopDefault(import('../pages/car/car_user_edit/_user_id.vue' /* webpackChunkName: "pages/car/car_user_edit/_user_id" */))
const _4769387e = () => interopDefault(import('../pages/company/company_detail/_company_id.vue' /* webpackChunkName: "pages/company/company_detail/_company_id" */))
const _92cc5392 = () => interopDefault(import('../pages/company/company_edit/_company_id.vue' /* webpackChunkName: "pages/company/company_edit/_company_id" */))
const _36bd9fcc = () => interopDefault(import('../pages/car/car_detail/_car_id/_car_data_id.vue' /* webpackChunkName: "pages/car/car_detail/_car_id/_car_data_id" */))
const _5e53c20a = () => interopDefault(import('../pages/car/car_engine/_car_id/_car_data_id.vue' /* webpackChunkName: "pages/car/car_engine/_car_id/_car_data_id" */))
const _74bb8f4a = () => interopDefault(import('../pages/car/car_forbidden_area/_car_id/_car_data_id.vue' /* webpackChunkName: "pages/car/car_forbidden_area/_car_id/_car_data_id" */))
const _7e39ffa2 = () => interopDefault(import('../pages/car/car_travelroute/_car_id/_car_data_id.vue' /* webpackChunkName: "pages/car/car_travelroute/_car_id/_car_data_id" */))
const _cf7705a6 = () => interopDefault(import('../pages/car/car_warning_area/_car_id/_car_data_id.vue' /* webpackChunkName: "pages/car/car_warning_area/_car_id/_car_data_id" */))
const _393973d6 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/notify",
    component: _7e9d5975,
    name: "notify"
  }, {
    path: "/password_reset",
    component: _1d4c87d7,
    name: "password_reset"
  }, {
    path: "/warning",
    component: _ba3e4ac0,
    name: "warning"
  }, {
    path: "/account/account_add",
    component: _21785731,
    name: "account-account_add"
  }, {
    path: "/account/account_list",
    component: _abdde284,
    name: "account-account_list"
  }, {
    path: "/account/password_reset",
    component: _9d7e9a88,
    name: "account-password_reset"
  }, {
    path: "/car/car_add",
    component: _39ed53ff,
    name: "car-car_add"
  }, {
    path: "/car/car_list",
    component: _bf88a8a0,
    name: "car-car_list"
  }, {
    path: "/car/car_user_add",
    component: _7dfc04bf,
    name: "car-car_user_add"
  }, {
    path: "/car/car_user_device_add",
    component: _69febd0a,
    name: "car-car_user_device_add"
  }, {
    path: "/car/car_user_list",
    component: _43f9da20,
    name: "car-car_user_list"
  }, {
    path: "/company/company_add",
    component: _5bbc685e,
    name: "company-company_add"
  }, {
    path: "/company/company_list",
    component: _0e962e1e,
    name: "company-company_list"
  }, {
    path: "/car/device/add",
    component: _0bcc00a2,
    name: "car-device-add"
  }, {
    path: "/car/device/list",
    component: _34b71400,
    name: "car-device-list"
  }, {
    path: "/car/device/detail/:car_device_id?",
    component: _48088e86,
    name: "car-device-detail-car_device_id"
  }, {
    path: "/car/device/edit/:car_device_id?",
    component: _2fd969ad,
    name: "car-device-edit-car_device_id"
  }, {
    path: "/account/account_detail/:account_id?",
    component: _b3bf2524,
    name: "account-account_detail-account_id"
  }, {
    path: "/account/account_edit/:account_id?",
    component: _456a99b2,
    name: "account-account_edit-account_id"
  }, {
    path: "/car/car_edit/:car_id?",
    component: _3e3ce0dc,
    name: "car-car_edit-car_id"
  }, {
    path: "/car/car_loges/:car_data_id?",
    component: _bc6f6c96,
    name: "car-car_loges-car_data_id"
  }, {
    path: "/car/car_user_detail/:user_id?",
    component: _7b6c7d6c,
    name: "car-car_user_detail-user_id"
  }, {
    path: "/car/car_user_edit/:user_id?",
    component: _0bb2f45a,
    name: "car-car_user_edit-user_id"
  }, {
    path: "/company/company_detail/:company_id?",
    component: _4769387e,
    name: "company-company_detail-company_id"
  }, {
    path: "/company/company_edit/:company_id?",
    component: _92cc5392,
    name: "company-company_edit-company_id"
  }, {
    path: "/car/car_detail/:car_id?/:car_data_id?",
    component: _36bd9fcc,
    name: "car-car_detail-car_id-car_data_id"
  }, {
    path: "/car/car_engine/:car_id?/:car_data_id?",
    component: _5e53c20a,
    name: "car-car_engine-car_id-car_data_id"
  }, {
    path: "/car/car_forbidden_area/:car_id?/:car_data_id?",
    component: _74bb8f4a,
    name: "car-car_forbidden_area-car_id-car_data_id"
  }, {
    path: "/car/car_travelroute/:car_id?/:car_data_id?",
    component: _7e39ffa2,
    name: "car-car_travelroute-car_id-car_data_id"
  }, {
    path: "/car/car_warning_area/:car_id?/:car_data_id?",
    component: _cf7705a6,
    name: "car-car_warning_area-car_id-car_data_id"
  }, {
    path: "/",
    component: _393973d6,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
